/*!
 *  Stunnplate script.js
 *  Version: 1.3.2
 *
 */
 /*
 *
 *  Notes:
 *  Main JS file to include entire library followed by custom scripts.
 *
 *
 */

/*-------------------------------------------------------*/
/*- SITE SPECIFIC SCRIPT                                -*/
/*-------------------------------------------------------*/

//This is no longer the place for page specific stuff. make sure you use _pagespecific.js for that! (040117 MG)


/********************************************************************/


$(function() {
  var wrapp_flex_ul = $("#wrapp-flex-ul");

	$("#menu-button-mobile").on("click", function() {
    $(".wrapp-flex-ul .flex-ul li a").removeClass("visible");

    var wrapp_flex_ul_li = $(".wrapp-flex-ul .flex-ul li"),
        duration = 100,
        i = 1;
    wrapp_flex_ul.addClass("open");
    setTimeout(function() {

      var timerId = setTimeout(function go() {
        $(".wrapp-flex-ul .flex-ul li:nth-child("+ i +") a").addClass("visible");
        if (i < wrapp_flex_ul_li.length) {
         setTimeout(go, duration);
        }
        i++;
      }, duration);

    }, 400);

  });

  $(".wrapp-flex-ul-close").on("click", function() {

    var wrapp_flex_ul_li = $(".wrapp-flex-ul .flex-ul li"),
        i = wrapp_flex_ul_li.length,
        duration = 0,
        timerId;

    /*timerId = setTimeout(function go() {
      $(".wrapp-flex-ul .flex-ul li:nth-child("+ i +") a").removeClass("visible");
      if (wrapp_flex_ul_li.length > 0) {
       setTimeout(go, duration);
      }
      i--;
      if (i == -2) {
        wrapp_flex_ul.removeClass("open");
      }
    }, duration);*/
    $(".wrapp-flex-ul .flex-ul li a").removeClass("visible");
    wrapp_flex_ul.removeClass("open");

  });



  $(".title-wrap h1, .title-wrap h2, .title-wrap h3, .title-wrap h4").each(function() {
    $(this).titleFirstWord();
  });

  ourServicesBtnHover();

  $(window).resize(function() {
    ourServicesBtnHover();
  });

  $('.top_link').on("click", function(e) {
      e.preventDefault();
  		$('body,html').animate({scrollTop:0},500);
  });
  $('.bottom_link').on("click", function(e) {
      e.preventDefault();
      var _this = $(this),
          container = _this.closest(".page_banner"),
          toTop = container.offset().top,
          containerHeight = container.height();

  		$('body,html').animate({scrollTop:(toTop+containerHeight)},500);
  });

  $(".accordeon_woodfood_trigger").on("click", function(e) {
			e.preventDefault();

			var $this = $(this),
					item = $this.closest(".accordeon_woodfood_item"),
					list = $this.closest(".accordeon_woodfood_list"),
					items = list.find(".accordeon_woodfood_item"),
					content = item.find(".accordeon_woodfood_inner"),
					otherContents = list.find(".accordeon_woodfood_inner"),
					duration = 300;

      if(!item.hasClass("active")) {
        items.removeClass("active");
        item.addClass("active");
        otherContents.stop(true, true).slideUp(duration);
        content.stop(true, true).slideDown(duration);
      } else {
        content.stop(true, true).slideUp(duration);
        item.removeClass("active")
      }
		});

});

function ourServicesBtnHover() {
  if ($(window).width() > 991) {
    $(".our_services-more").hover(
      function() {
        $(this).closest(".our_services-item").addClass("hover");
      }, function() {
        $(this).closest(".our_services-item").removeClass("hover");
      }
    );
  } else {
    $(".our_services-item").removeClass("hover");
  }
}

$.fn.titleFirstWord = function() {

    //console.log($(this).find(".title_first_word"));
    if($(this).find(".title_first_word").length > 0 ){
        //already has a manually added .title_first_word child
        return;
    }
  var text = this.text().trim().split(" ");
  var first = text.shift();
  this.html((text.length > 0 ? "<span class='title_first_word'>"+ first + "</span> " : first) + text.join(" "));
};
